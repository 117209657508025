import React, { useState } from "react";
import styles from "./FontSelector.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const fonts = [
  "Lato",
  "Roboto",
  "Alegreya",
  "Anton",
  "Archivo Black",
  "Arimo",
  "Bebas Neue",
  "Cormorant Garamond",
  "Homemade Apple",
  "Josefin Sans",
  "Libre Baskerville",
  "Montserrat",
  "Old Standard TT",
  "Oswald",
  "Playfair Display",
  "Roboto Slab",
  "Rock Salt",
  "Shadows Into Light",
  "Sigmar One",
  "Source Code Pro",
  "Syncopate",
];

// Importa i font da Google Fonts

interface FontSelectorProps {
  title: string;
  fontSelected: (font: string) => void;
}

const FontSelector: React.FC<FontSelectorProps> = (props) => {
  const [selectedFont, setSelectedFont] = useState<string>("Roboto");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  return (
    <div
      className={`${styles.container} text-center col-12 d-flex flex-column align-items-center justify-content-center `}
    >
      {/* Import Google Fonts */}
      {/* <link rel="stylesheet" href={fontImportUrl} /> */}

      <h2 className={`${styles.title}`} style={{ fontFamily: selectedFont }}>
        {props.title}
      </h2>

      {/* MENU CUSTOM */}
      <div className={`${styles.custom_dropdown} d-flex flex-row col-6 `}>
        <div
          className={`${styles.dropdown_toggle} text-center justify-content-between col-12 d-flex `}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          style={{ fontFamily: selectedFont }}
        >
          <span className="col- text-center">{selectedFont}</span>
          <span className="col-2 text-end">
            <FontAwesomeIcon icon={isDropdownOpen ? faAngleUp : faAngleDown} />
          </span>
        </div>

        {isDropdownOpen && (
          <ul className={`${styles.dropdown_menu} ${styles.show}`}>
            {fonts.map((font) => (
              <li
                key={font}
                className={`${styles.dropdown_item}`}
                style={{ fontFamily: font }}
                onClick={() => {
                  setSelectedFont(font);
                  props.fontSelected(font);
                  setIsDropdownOpen(false);
                }}
              >
                {font}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* <div className={`${styles.preview_box} mt-4`}>
        <p
          className={`${styles.preview_text}`}
          style={{ fontFamily: selectedFont }}
        >
          Questo è un testo di esempio con il font: <b>{selectedFont}</b>
        </p>
      </div> */}
    </div>
  );
};

export default FontSelector;
