import { useEffect, useRef, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import styles from "./CardDetailCreation.module.scss";
import Dropzone from "react-dropzone";
import { NfcIcon } from "lucide-react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";
import WebsiteStore, { setCurrentPrecontact } from "../../store/WebsiteStore";
import { useStoreState } from "pullstate";
import { getCurrentPrecontact } from "../../store/Selector";
import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import faggio from "../../assets/FaggioCard.png";
import bamboo from "../../assets/BambooCard.png";
import gold from "../../assets/gold.png";
import silver from "../../assets/silver.png";
import white from "../../assets/white.png";
import cherry from "../../assets/cherry.png";
import black from "../../assets/black.png";

import { RgbColorPicker } from "react-colorful";
import FontSelector from "../../components/FontSelector/FontSelector";

type Material = "metal" | "pvc" | "wood";

type MaterialColor = {
  color: string;
  image: any;
};

const CardDetailCreation: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const [image, setImage] = useState<File>();
  const [design, setDesign] = useState<File>();
  const [logo, setLogo] = useState<File>();
  const [name, setName] = useState("Name");
  const [surname, setSurname] = useState("Surname");
  const [companyName, setCompanyName] = useState("Company Name");
  const [nameSurnameFont, setNameSurnameFont] = useState<string>("Roboto");
  const [companyFont, setCompanyFont] = useState<string>("Roboto");
  const [email, setEmail] = useState("");
  const [orderId, setOrderId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [qrColor, setQrColor] = useState({ r: 255, g: 255, b: 255 });
  const [qrRGB, setQrRGB] = useState("");
  const [notes, setNotes] = useState("");
  const [material, setMaterial] = useState<Material>("pvc");
  const [cardColor, setCardColor] = useState<MaterialColor>({
    color: "black",
    image: black,
  });
  const [plan, setPlan] = useState("essential");
  const [printType, setPrintType] = useState("printed");
  const metalColor: MaterialColor[] = [
    { color: "black", image: black },
    { color: "gold", image: gold },
    { color: "silver", image: silver },
  ];
  const pvcColor: MaterialColor[] = [
    { color: "black", image: black },
    { color: "white", image: white },
  ];
  const woodColor: MaterialColor[] = [
    { color: "cherry", image: cherry },
    { color: "beech", image: faggio },
    { color: "bamboo", image: bamboo },
  ];

  const containerRef = useRef<HTMLDivElement>(null);
  const squareRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingLine, setIsDraggingLine] = useState(false);
  const [isDraggingLogo, setIsDraggingLogo] = useState(false);
  const [isDraggingImage, setIsDraggingImage] = useState(false);
  const [isDraggingCompany, setIsDraggingCompany] = useState(false);
  const [targetX, setTargetX] = useState(120); // Posizione iniziale
  const [targetY, setTargetY] = useState(50); // Posizione iniziale
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [offsetXLine, setOffsetXLine] = useState(0);
  const [offsetYLine, setOffsetYLine] = useState(0);
  const [offsetXCompany, setOffsetXCompany] = useState(0);
  const [offsetYCompany, setOffsetYCompany] = useState(0);
  const [targetLineX, setTargetLineX] = useState(90); // Posizione iniziale linea
  const [targetLineY, setTargetLineY] = useState(155); // Posizione iniziale linea
  const [targetCompanyX, setTargetCompanyX] = useState(90); // Posizione iniziale linea
  const [targetCompanyY, setTargetCompanyY] = useState(175); // Posizione iniziale linea
  const [offsetLogoX, setOffsetLogoX] = useState(0);
  const [offsetLogoY, setOffsetLogoY] = useState(0);
  const [targetLogoX, setTargetLogoX] = useState(90); // Posizione iniziale logo
  const [targetLogoY, setTargetLogoY] = useState(155); // Posizione iniziale logo
  const [offsetImageX, setOffsetImageX] = useState(0);
  const [offsetImageY, setOffsetImageY] = useState(0);
  const [targetImageX, setTargetImageX] = useState(0); // Posizione iniziale image
  const [targetImageY, setTargetImageY] = useState(0); // Posizione iniziale image
  const [nameDimensions, setNameDimensions] = useState(16.5);
  const [companyDimensions, setCompanyDimensions] = useState(16.5);
  const [qrDimensions, setQrDimensions] = useState(70);
  const [sameContact, setSameContact] = useState(true);
  const [multipleCards, setMultipleCards] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [originalDimensionsImage, setOriginalDimensionsImage] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [scale, setScale] = useState<number>(0.7); // Valore di scala, parte da 1 (100%)
  const [scaleImage, setScaleImage] = useState<number>(20); // Valore di scala, parte da 1 (100%)

  const getQRColorString = () => {
    const { r, g, b } = qrColor;

    setQrRGB(`rgb(${r}, ${g}, ${b})`);
  };

  useEffect(() => {
    getQRColorString();
  }, [qrColor]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CardDesignData>();

  const onSubmit = async (data: CardDesignData) => {
    data.name = name;
    data.surname = surname;
    data.companyName = companyName;
    data.nameFont = nameSurnameFont;
    data.companyFont = companyFont;
    data.company_dimensions = companyDimensions;
    data.company_xy = targetCompanyX + "," + targetCompanyY;
    data.orderId = orderId;
    data.email = email;
    data.color = cardColor.color;
    data.material = material;
    data.qr_xy = targetX + "," + targetY;
    data.name_xy = targetLineX + "," + targetLineY;
    data.print_color = qrRGB;
    data.print_type = printType;
    data.notes = notes;
    data.plan = plan;
    data.multipleCards = multipleCards ? 1 : 0;
    data.sameContact = sameContact ? 1 : 0;
    data.qr_dimensions = qrDimensions;
    data.name_dimensions = nameDimensions;
    data.logo_scale = material === "metal" ? scale : scaleImage;
    data.logo_xy =
      material === "metal"
        ? targetLogoX + "," + targetLogoY
        : targetImageX + "," + targetImageY;
    data.logo_original_dimensions =
      material === "metal"
        ? originalDimensions?.width + "," + originalDimensions?.height
        : originalDimensionsImage?.width +
          "," +
          originalDimensionsImage?.height;
    // console.log(data);
    if (image) {
      data.image = image;
      //   console.log(data);
    }
    if (design) {
      data.design = design;
      //   console.log(data);
    }
    await axiosHelper
      .createCardDesign(data)
      .then((response) => {
        setEmail("");
        setImage(undefined);
        setDesign(undefined);
        setName("Name");
        setSurname("Surname");
        setCompanyName("Company Name");
        if (!multipleCards) {
          setOrderId("");
        }
        setNotes("");
        setPlan("essential");
        setMaterial("pvc");
        setTargetX(120);
        setTargetY(50);
        setTargetLineX(90);
        setTargetLineY(155);
        setTargetImageX(0);
        setTargetImageY(0);
        setTargetLogoX(0);
        setTargetLogoY(0);
        setCardColor({ color: "black", image: black });
        setQrColor({ r: 255, g: 255, b: 255 });

        setSuccessMessage("Your design info has been sent!");
      })
      .catch((error) => console.log(error));
  };

  const onDropImage = (e: any) => {
    // console.log(e?.[0]);
    setImage(e?.[0]);
  };

  const onDropDesign = (e: any) => {
    // console.log(e?.[0]);
    setDesign(e?.[0]);
  };

  const onDropLogo = (e: any) => {
    // console.log(e?.[0]);
    setLogo(e?.[0]);
    setTargetLineX(8);
    setTargetLineY(175);
    setTargetX(225);
    setTargetY(106);
  };

  useEffect(() => {
    if (!logo) {
      setImageSrc(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = () => {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        // Limiti di dimensione
        const maxWidth = 300;
        const maxHeight = 190;
        const minWidth = 50;
        const minHeight = 80;

        // Calcola la scala per rientrare nei limiti massimi
        const widthRatio = maxWidth / width;
        const heightRatio = maxHeight / height;
        let minRatio = Math.min(widthRatio, heightRatio, 1); // Assicuriamo che non superi i limiti massimi

        width = Math.round(width * minRatio);
        height = Math.round(height * minRatio);

        // Se l'immagine ridotta è inferiore ai minimi, la portiamo almeno ai minimi
        const minWidthRatio = minWidth / width;
        const minHeightRatio = minHeight / height;
        let maxRatio = Math.max(minWidthRatio, minHeightRatio, 1);

        width = Math.max(Math.round(width * maxRatio), minWidth);
        height = Math.max(Math.round(height * maxRatio), minHeight);

        setOriginalDimensions({ width, height });
        setImageSrc(img.src);
      };
    };

    reader.readAsDataURL(logo);
  }, [logo]);

  useEffect(() => {
    if (!image) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = () => {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        // Limiti di dimensione
        const maxWidth = 300;
        const maxHeight = 190;
        const minWidth = 50;
        const minHeight = 80;

        // Calcola la scala per rientrare nei limiti massimi
        const widthRatio = maxWidth / width;
        const heightRatio = maxHeight / height;
        let minRatio = Math.min(widthRatio, heightRatio, 1); // Assicuriamo che non superi i limiti massimi

        width = Math.round(width * minRatio);
        height = Math.round(height * minRatio);

        // Se l'immagine ridotta è inferiore ai minimi, la portiamo almeno ai minimi
        const minWidthRatio = minWidth / width;
        const minHeightRatio = minHeight / height;
        let maxRatio = Math.max(minWidthRatio, minHeightRatio, 1);

        width = Math.max(Math.round(width * maxRatio), minWidth);
        height = Math.max(Math.round(height * maxRatio), minHeight);

        setOriginalDimensionsImage({ width, height });
      };
    };

    reader.readAsDataURL(image);
  }, [image]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent | TouchEvent) => {
      if (!isDragging || !containerRef.current || !squareRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const squareSize = squareRef.current.clientWidth;

      event.preventDefault();

      let clientX, clientY;
      if ("touches" in event) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      // Calcola nuova posizione considerando l'offset del primo tocco
      const x = clientX - rect.left - offsetX;
      const y = clientY - rect.top - offsetY;

      // Impedisce al quadrato di uscire dai bordi
      const maxX = rect.width - squareSize;
      const maxY = rect.height - squareSize;
      const minX = 0;
      const minY = 0;

      setTargetX(Math.min(maxX, Math.max(minX, x)));
      setTargetY(Math.min(maxY, Math.max(minY, y)));
    };

    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none"; // Evita gesture su mobile
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    };

    if (isDragging) {
      disableScroll(); // Blocca lo scroll mentre trascini
    }

    const handleMouseUp = () => {
      setIsDragging(false);
      enableScroll();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove, { passive: false });
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);

      enableScroll();
    };
  }, [isDragging, offsetX, offsetY]);

  useEffect(() => {
    const handleMouseMoveLine = (event: MouseEvent | TouchEvent) => {
      if (!isDraggingLine || !containerRef.current || !lineRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const lineWidth = lineRef.current.clientWidth;
      const lineHeight = lineRef.current.clientHeight;

      let clientX, clientY;
      if ("touches" in event) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      const x = clientX - rect.left;
      const y = clientY - rect.top;

      // Impedisce al quadrato di uscire dai bordi
      const maxX = rect.width - lineWidth;
      const maxY = rect.height - lineHeight;
      const minX = 0;
      const minY = 0;

      setTargetLineX(Math.min(maxX, Math.max(minX, x)));
      setTargetLineY(Math.min(maxY, Math.max(minY, y)));
    };

    const handleMouseUp = () => {
      setIsDraggingLine(false);
      enableScroll();
    };

    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none"; // Evita gesture su mobile
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    };

    if (isDraggingLine) {
      disableScroll(); // Blocca lo scroll mentre trascini
    }

    document.addEventListener("mousemove", handleMouseMoveLine);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMoveLine, {
      passive: false,
    });
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMoveLine);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMoveLine);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [isDraggingLine, offsetXLine, offsetYLine]);

  useEffect(() => {
    const handleMouseMoveLine = (event: MouseEvent | TouchEvent) => {
      if (!isDraggingCompany || !containerRef.current || !companyRef.current)
        return;

      const rect = containerRef.current.getBoundingClientRect();
      const lineWidth = companyRef.current.clientWidth;
      const lineHeight = companyRef.current.clientHeight;

      let clientX, clientY;
      if ("touches" in event) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      const x = clientX - rect.left;
      const y = clientY - rect.top;

      // Impedisce al quadrato di uscire dai bordi
      const maxX = rect.width - lineWidth;
      const maxY = rect.height - lineHeight;
      const minX = 0;
      const minY = 0;

      setTargetCompanyX(Math.min(maxX, Math.max(minX, x)));
      setTargetCompanyY(Math.min(maxY, Math.max(minY, y)));
    };

    const handleMouseUp = () => {
      setIsDraggingCompany(false);
      enableScroll();
    };

    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none"; // Evita gesture su mobile
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    };

    if (isDraggingCompany) {
      disableScroll(); // Blocca lo scroll mentre trascini
    }

    document.addEventListener("mousemove", handleMouseMoveLine);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMoveLine, {
      passive: false,
    });
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMoveLine);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMoveLine);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [isDraggingCompany, offsetXCompany, offsetYCompany]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent | TouchEvent) => {
      if (!isDraggingLogo || !containerRef.current || !logoRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const logoWidth = logoRef.current.clientWidth;
      const logoHeight = logoRef.current.clientHeight;

      event.preventDefault();

      let clientX, clientY;
      if ("touches" in event) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      // Calcola nuova posizione considerando l'offset del primo tocco
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      // Impedisce al quadrato di uscire dai bordi
      const maxX = rect.width - logoWidth;
      const maxY = rect.height - logoHeight;
      const minX = 0;
      const minY = 0;

      setTargetLogoX(Math.min(maxX, Math.max(minX, x)));
      setTargetLogoY(Math.min(maxY, Math.max(minY, y)));
    };

    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none"; // Evita gesture su mobile
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    };

    if (isDraggingLogo) {
      disableScroll(); // Blocca lo scroll mentre trascini
    }

    const handleMouseUp = () => {
      setIsDraggingLogo(false);
      enableScroll();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove, { passive: false });
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);

      enableScroll();
    };
  }, [isDraggingLogo, offsetLogoX, offsetLogoY]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent | TouchEvent) => {
      if (!isDraggingImage || !cardRef.current || !imageRef.current) return;

      const rect = cardRef.current.getBoundingClientRect();
      const logoWidth = imageRef.current.clientWidth;
      const logoHeight = imageRef.current.clientHeight;

      event.preventDefault();

      let clientX, clientY;
      if ("touches" in event) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      // Calcola nuova posizione considerando l'offset del primo tocco
      const x = clientX - rect.left;
      const y = clientY - rect.top;

      // Impedisce al quadrato di uscire dai bordi
      const maxX = rect.width - logoWidth;
      const maxY = rect.height - logoHeight;
      const minX = 0;
      const minY = 0;

      setTargetImageX(Math.min(maxX, Math.max(minX, x)));
      setTargetImageY(Math.min(maxY, Math.max(minY, y)));
    };

    const disableScroll = () => {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none"; // Evita gesture su mobile
    };

    const enableScroll = () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
    };

    if (isDraggingImage) {
      disableScroll(); // Blocca lo scroll mentre trascini
    }

    const handleMouseUp = () => {
      setIsDraggingImage(false);
      enableScroll();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove, { passive: false });
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);

      enableScroll();
    };
  }, [isDraggingImage, offsetImageX, offsetImageY]);

  const startDragging = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDragging(true);

    let clientX, clientY;
    if ("touches" in event) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    if (squareRef.current) {
      const rect = squareRef.current.getBoundingClientRect();
      setOffsetX(clientX - rect.left);
      setOffsetY(clientY - rect.top);
    }
  };

  const startDraggingLine = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDraggingLine(true);

    let clientX, clientY;
    if ("touches" in event) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    if (squareRef.current) {
      const rect = squareRef.current.getBoundingClientRect();
      setOffsetXLine(clientX - rect.left);
      setOffsetYLine(clientY - rect.top);
    }
  };

  const startDraggingCompany = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDraggingCompany(true);

    let clientX, clientY;
    if ("touches" in event) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    if (squareRef.current) {
      const rect = squareRef.current.getBoundingClientRect();
      setOffsetXCompany(clientX - rect.left);
      setOffsetYCompany(clientY - rect.top);
    }
  };

  const startDraggingLogo = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDraggingLogo(true);

    let clientX, clientY;
    if ("touches" in event) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    if (squareRef.current) {
      const rect = squareRef.current.getBoundingClientRect();
      setOffsetLogoX(clientX - rect.left);
      setOffsetLogoY(clientY - rect.top);
    }
  };

  const startDraggingImage = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    setIsDraggingImage(true);

    let clientX, clientY;
    if ("touches" in event) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setOffsetImageX(clientX - rect.left);
      setOffsetImageY(clientY - rect.top);
    }
  };

  const decreaseQrDimensions = () => {
    if (qrDimensions > 50) {
      setQrDimensions(qrDimensions - 1);
    }
  };

  return (
    <>
      <Header dark={false} selected={""} />
      <div className="col-12 d-flex flex-row align-items-start justify-content-center p-5 p-md-0 pt-5 flex-wrap "></div>
      <div
        className="p-5 d-flex flex-column align-items-center justify-content-start "
        style={{ userSelect: "none" }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" col-12 rounded color-black "
        >
          <div>
            <div className=" d-flex flex-row  col-12 align-items-center justify-content-center  text-center">
              <span className="esquare-title">
                Fill the form to create your <br className="d-md-none" />
                card design
              </span>
            </div>
            <div className=" text-center d-flex flex-row align-items-center justify-content-center mt-10  col-12  ">
              <span className="sub-title col-md-4 col-12">
                Once you fill the form below, you will receive a mail from us
                with your card design to review them.
              </span>
            </div>

            <div className=" text-center d-flex flex-row align-items-center justify-content-center mt-10  col-12  ">
              <div className="d-flex flex-column align-items-center justify-content-center col-12">
                <span className="sub-title col-md-4 col-12">
                  If you have more than one card in your order:
                  <br /> Please complete the form multiple times if you want
                  different designs.
                </span>
                <span className="sub-title col-md-4 col-12 mt-5">
                  My order includes:
                </span>
                <div className="d-flex flex-row col-md-4 col-12 align-items-center justify-content-around mt-3">
                  <span
                    className={`${styles.materialBottone} ${
                      !multipleCards && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    onClick={() => {
                      setMultipleCards(false);
                    }}
                  >
                    One card
                  </span>
                  <span
                    className={`${styles.materialBottone} ${
                      multipleCards && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    onClick={() => {
                      setMultipleCards(true);
                    }}
                  >
                    Multiple cards
                  </span>
                </div>
                {multipleCards && (
                  <>
                    <span className="sub-title col-md-4 col-12 mt-5">
                      Do you want the same contact on your business cards?
                    </span>
                    <div className="d-flex flex-row col-md-4 col-12 align-items-center justify-content-around mt-3">
                      <span
                        className={`${styles.materialBottone} ${
                          sameContact && styles.materialBottoneActive
                        } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                        onClick={() => {
                          setSameContact(true);
                        }}
                      >
                        Same contacts
                      </span>
                      <span
                        className={`${styles.materialBottone} ${
                          !sameContact && styles.materialBottoneActive
                        } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                        onClick={() => {
                          setSameContact(false);
                        }}
                      >
                        Different contacts
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-5 ">
              <div className="d-flex flex-column col-md-5 col-12 align-items-center justify-content-start">
                <span className="sub-title">Your Plan :</span>
                <div className="d-flex flex-row col-md-8 col-12 align-items-center justify-content-around mt-3">
                  <span
                    className={`${styles.materialBottone} ${
                      plan === "essential" && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    onClick={() => {
                      setPlan("essential");
                    }}
                  >
                    Essential
                  </span>
                  <span
                    className={`${styles.materialBottone} ${
                      plan === "custom" && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-5 sub-title-esquare`}
                    onClick={() => {
                      setPlan("custom");
                    }}
                  >
                    Custom
                  </span>
                </div>
                <span className="sub-title mt-5">Card Material :</span>
                <div className="d-flex flex-row col-12 align-items-center justify-content-around mt-3">
                  <span
                    className={`${styles.materialBottone} ${
                      material === "pvc" && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-3 sub-title-esquare`}
                    onClick={() => {
                      setMaterial("pvc");
                      setCardColor({ color: "black", image: black });
                      setPrintType("printed");
                      setQrColor({ r: 255, g: 255, b: 255 });
                      setTargetLineX(90);
                      setTargetLineY(155);
                      setTargetX(120);
                      setTargetY(50);
                      setImageSrc(null);
                      setLogo(undefined);
                    }}
                  >
                    PVC
                  </span>
                  <span
                    className={`${styles.materialBottone} ${
                      material === "wood" && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-md-3 col-4 sub-title-esquare`}
                    onClick={() => {
                      setMaterial("wood");
                      setImageSrc(null);
                      setLogo(undefined);
                      setCardColor({ color: "cherry", image: cherry });
                      printType === "engraved" &&
                        setQrColor({ r: 107, g: 70, b: 22 });
                      setTargetLineX(90);
                      setTargetLineY(155);
                      setTargetX(120);
                      setTargetY(50);
                    }}
                  >
                    WOOD
                  </span>
                  <span
                    className={`${styles.materialBottone} ${
                      material === "metal" && styles.materialBottoneActive
                    } d-flex flex-row align-items-center justify-content-center col-md-3 col-4 sub-title-esquare`}
                    onClick={() => {
                      setMaterial("metal");
                      setCardColor({ color: "silver", image: silver });
                      setPrintType("printed");
                      setQrColor({ r: 255, g: 255, b: 255 });
                    }}
                  >
                    METAL
                  </span>
                </div>
                {material === "wood" && (
                  <>
                    <span className="sub-title mt-5"> Printing Type :</span>
                    <div className="d-flex flex-row col-md-11 col-12 align-items-center justify-content-around mt-3">
                      <span
                        className={`${styles.materialBottone} ${
                          printType === "engraved" &&
                          styles.materialBottoneActive
                        } d-flex flex-row align-items-center justify-content-center p-4 sub-title-esquare`}
                        onClick={() => {
                          setPrintType("engraved");
                          setQrRGB("rgb(107,70,22)");
                        }}
                      >
                        Laser Engraving
                      </span>
                      <span
                        className={`${styles.materialBottone} ${
                          printType === "printed" &&
                          styles.materialBottoneActive
                        } d-flex flex-row align-items-center justify-content-center p-4 sub-title-esquare`}
                        onClick={() => {
                          setPrintType("printed");
                          setQrRGB("rgb(255,255,255)");
                        }}
                      >
                        Color Printing
                      </span>
                    </div>
                  </>
                )}
                <span className="sub-title mt-5"> Card Color :</span>

                <div className="d-flex flex-row col-md-5 col-8 align-items-center justify-content-around mt-3">
                  {material === "metal" &&
                    metalColor.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex flex-column align-items-center justify-content-center "
                      >
                        <span
                          className={`${styles.colorBottone} ${
                            cardColor.color === item.color &&
                            styles.colorBottoneActive
                          }`}
                          onClick={() => {
                            setCardColor(item);
                            item.color === "black" &&
                              setQrColor({ r: 255, g: 255, b: 255 });
                          }}
                        >
                          <img src={item.image} />
                        </span>
                        <span className="mt-3 text">{item.color}</span>
                      </div>
                    ))}
                  {material === "wood" &&
                    woodColor.map((item, index) => (
                      <span
                        key={index}
                        className={`${styles.colorBottone} ${
                          cardColor.color === item.color &&
                          styles.colorBottoneActive
                        }`}
                        onClick={() => setCardColor(item)}
                      >
                        <img src={item.image} />
                      </span>
                    ))}
                  {material === "pvc" &&
                    pvcColor.map((item, index) => (
                      <span
                        key={index}
                        className={`${styles.colorBottone} ${
                          cardColor.color === item.color &&
                          styles.colorBottoneActive
                        }`}
                        onClick={() => {
                          setCardColor(item);
                          item.color === "white"
                            ? setQrColor({ r: 0, g: 0, b: 0 })
                            : setQrColor({ r: 255, g: 255, b: 255 });
                        }}
                      >
                        <img src={item.image} />
                      </span>
                    ))}
                </div>
              </div>
            </div>

            <div className=" d-flex flex-column  pb-5 align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Name * :
              </div>
              <div className="col-12 col-md-4 mt-3 ">
                <input
                  type="text"
                  placeholder="name"
                  value={name}
                  required
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Surname * :
              </div>
              <div className="col-12 col-md-4 mt-3">
                <input
                  type="text"
                  placeholder="surname"
                  value={surname}
                  required
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column  pb-5 align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Company Name :
              </div>
              <div className="col-12 col-md-4 mt-3 ">
                <input
                  type="text"
                  placeholder="company name"
                  value={companyName}
                  // required
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Order Id * :
              </div>
              <div className="col-12 col-md-4 mt-3">
                <input
                  type="text"
                  placeholder="order id"
                  required
                  value={orderId}
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Order Email * :
              </div>
              <div className="col-12 col-md-4 mt-3 ">
                <input
                  type="email"
                  placeholder="email"
                  required
                  value={email}
                  className={`text-center w-100 sub-title ${styles.inputText}`}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {plan === "custom" && material !== "metal" && (
                <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  mt-5 ">
                  Logo / Front Design :
                </div>
              )}
            </div>

            {!image && plan === "custom" && material !== "metal" && (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center col-12  ">
                  <div
                    className={`  bg-light col-md-5 col-12 d-flex flex-row align-items-center justify-content-center ${styles.dropImage}`}
                  >
                    <Dropzone
                      onDrop={onDropImage}
                      accept={"image/png, image/jpg, image/jpeg, image/svg"}
                      maxSize={31457280}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "black",
                            borderRadius: "10px",
                          }}
                          className={` sub-title  d-flex align-items-center  justify-content-center `}
                        >
                          <div
                            className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <span>
                              Drag and drop an image or <br />
                              <span className="color-blue">
                                choose a file
                              </span>{" "}
                              from your device <br />
                              Allowed formats: .jpg, .png
                            </span>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </>
            )}
            <div
              className={`col-12 mb-7 ${
                image && plan === "custom" && material !== "metal"
                  ? "d-flex flex-row align-items-center justify-content-center"
                  : "d-none"
              }`}
            >
              <div
                className={`col-12 col-md-6   d-flex flex-column  align-items-center justify-content-center `}
              >
                {image && originalDimensionsImage && (
                  <>
                    <div className=" mt-4 d-flex col-12 flex-column align-items-center justify-content-center ">
                      <div className=" d-flex align-items-center justify-content-end col-8 ">
                        <div
                          className={` ${styles.removeButton}`}
                          onClick={() => {
                            setImage(undefined);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faXmark as IconProp}
                            className=" text-16 me-0 color-red"
                          />
                        </div>
                      </div>
                      <div className={`${styles.previewImg} col-6 `}>
                        <img
                          alt="contactImage"
                          src={URL.createObjectURL(image)}
                          //   className={`${styles.previewImg}`}
                        />
                      </div>

                      <div className="d-flex flex-row col-12 text mt-5 align-items-center justify-content-center">
                        <i>
                          You can move the logo inside the card by dragging them
                        </i>
                      </div>

                      <div
                        className={`${styles.cardContainer} d-flex flex-row align-items-center justify-content-center col-12`}
                      >
                        <span
                          ref={cardRef}
                          className={`${styles.card}   col-12`}
                        >
                          {image && originalDimensionsImage && (
                            <>
                              <span
                                ref={imageRef}
                                className={`${styles.namesurname} `}
                                onMouseDown={startDraggingImage}
                                onTouchStart={startDraggingImage}
                                style={{
                                  maxWidth: `100%`,
                                  width:
                                    (originalDimensionsImage.width *
                                      scaleImage) /
                                    100,
                                  height:
                                    (originalDimensionsImage.height *
                                      scaleImage) /
                                    100,
                                  transform: `translate(${targetImageX}px, ${targetImageY}px)`,
                                }}
                              >
                                <img src={URL.createObjectURL(image)} />
                              </span>
                            </>
                          )}

                          <img src={cardColor.image} />
                        </span>
                      </div>

                      <div className="d-flex flex-column col-md-2 col-8 mt-5 text-center ">
                        <span className="sub-title">Logo Size</span>

                        <div className=" col-12 mt-3">
                          <input
                            id="rangeInput"
                            className="col-12 "
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            value={scaleImage}
                            onChange={(e) =>
                              setScaleImage(parseFloat(e.target.value))
                            }
                            style={{
                              background: `linear-gradient(to right, black ${scaleImage}% , #ddd ${scaleImage}%)`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {plan === "custom" && material !== "metal" && (
              <div className="d-flex flex-column align-items-center justify-content-center col-12 ">
                <div className="sub-title d-flex flex-column align-items-center justify-content-center col-12 col-md-4  mt-5 ">
                  Back Design :
                  <span className="text-s col-8 text-center">
                    We recommend adding a QR code position in your custom back
                    design
                  </span>
                </div>
              </div>
            )}

            {!design && plan === "custom" && material !== "metal" && (
              // {!design && plan === "custom" && (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center col-12 ">
                  <div
                    className={`  bg-light col-md-5 col-12 d-flex flex-row align-items-center justify-content-center ${styles.dropImage} mt-5`}
                  >
                    <Dropzone
                      onDrop={onDropDesign}
                      accept={"image/png, image/jpg, image/jpeg, image/svg"}
                      maxSize={31457280}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "black",
                            borderRadius: "10px",
                          }}
                          className={` sub-title  d-flex align-items-center  justify-content-center `}
                        >
                          <div
                            className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <span>
                              Drag and drop an image or <br />
                              <span className="color-blue">
                                choose a file
                              </span>{" "}
                              from your device <br />
                              Allowed formats: .jpg, .png
                            </span>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </>
            )}
            <div
              className={`col-12 mb-7 ${
                design && plan === "custom"
                  ? "d-flex flex-row align-items-center justify-content-center"
                  : "d-none"
              }`}
            >
              <div
                className={`col-12 col-md-6   d-flex flex-column  align-items-center justify-content-center `}
              >
                {design && (
                  <>
                    <div className=" mt-4 d-flex col-12 flex-column align-items-center justify-content-center">
                      <div className=" d-flex align-items-center justify-content-end col-8">
                        <div
                          className={` ${styles.removeButton}`}
                          onClick={() => {
                            setDesign(undefined);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faXmark as IconProp}
                            className=" text-16 me-0 color-red"
                          />
                        </div>
                      </div>
                      <div className={`${styles.previewImg} col-6 `}>
                        <img
                          alt="contactImage"
                          src={URL.createObjectURL(design)}
                          //   className={`${styles.previewImg}`}
                        />
                      </div>
                      {/* <div className="d-flex mt-4 flex-row  align-items-center justify-content-center ">
                        <span className="text-xl">{design.name}</span>
                      </div> */}
                      <div
                        className={`${styles.cardContainerLogo} d-flex flex-row align-items-center justify-content-center col-12`}
                      >
                        <span
                          className={`${styles.cardLogo} d-flex flex-row align-items-center justify-content-center col-12`}
                        >
                          <span
                            className={`${styles.logo} d-flex flex-column align-items-center justify-content-center `}
                          >
                            <img src={URL.createObjectURL(design)} />
                          </span>
                          <img src={cardColor.image} />
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {plan === "custom" && material === "metal" && (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center col-12 ">
                  <div className="sub-title d-flex flex-column align-items-center justify-content-center col-12 col-md-4  mt-5 ">
                    Logo :
                  </div>
                </div>
              </>
            )}
            {!logo && plan === "custom" && material === "metal" && (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center col-12 ">
                  <div
                    className={`  bg-light col-md-5 col-12 d-flex flex-row align-items-center justify-content-center ${styles.dropImage} mt-5`}
                  >
                    <Dropzone
                      onDrop={onDropLogo}
                      accept={"image/png, image/jpg, image/jpeg, image/svg"}
                      maxSize={31457280}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "black",
                            borderRadius: "10px",
                          }}
                          className={` sub-title  d-flex align-items-center  justify-content-center `}
                        >
                          <div
                            className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <span>
                              Drag and drop an image or <br />
                              <span className="color-blue">
                                choose a file
                              </span>{" "}
                              from your device <br />
                              Allowed formats: .jpg, .png
                            </span>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </>
            )}
            <div
              className={`col-12 mb-7 ${
                logo && plan === "custom"
                  ? "d-flex flex-row align-items-center justify-content-center"
                  : "d-none"
              }`}
            >
              <div
                className={`col-12 col-md-6   d-flex flex-column  align-items-center justify-content-center `}
              >
                {imageSrc && originalDimensions && (
                  <>
                    <div className=" mt-4 d-flex col-12 flex-column align-items-center justify-content-center">
                      <div className=" d-flex align-items-center justify-content-end col-8">
                        <div
                          className={` ${styles.removeButton}`}
                          onClick={() => {
                            setImageSrc(null);
                            setLogo(undefined);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faXmark as IconProp}
                            className=" text-16 me-0 color-red"
                          />
                        </div>
                      </div>
                      <div className={`${styles.previewImg} col-6 `}>
                        <img
                          src={imageSrc}
                          width={originalDimensions.width * scale}
                          height={originalDimensions.height * scale}
                          alt="Preview"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
                Notes :
              </div>
              <div className="col-12 col-md-4 mt-3">
                <textarea
                  placeholder="Add notes"
                  value={notes}
                  className={`text-center w-100 sub-title ${styles.textarea}`}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`  d-flex flex-column col-12 align-items-center justify-content-center `}
            >
              <div
                className={`  d-flex flex-column align-items-center justify-content-center col-12 `}
              >
                <div className="d-flex flex-column col-md-3 col-8 mt-5 text-center ">
                  <span className="sub-title col-12">QR Code Size</span>
                  <div
                    className={`${styles.dimesionsChanger}  d-flex flex-row mt-4  col-12 align-items-center justify-content-between  `}
                  >
                    <span
                      className={`${styles.btnDimensions} d-flex flex-row   align-items-center justify-content-center`}
                      onClick={() => decreaseQrDimensions()}
                    >
                      <FontAwesomeIcon icon={faMinus as IconProp} />
                    </span>
                    <span className="text-center text ">
                      {Math.round(qrDimensions * 10) / 10}
                    </span>
                    <span
                      className={`${styles.btnDimensions} d-flex flex-row align-items-center justify-content-center `}
                      onClick={() => setQrDimensions(qrDimensions + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus as IconProp} />
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column col-md-3 col-8 mt-5 text-center  ">
                  <span className="sub-title col-12">Name/Surname Size</span>
                  <div
                    className={`${styles.dimesionsChanger}  d-flex flex-row mt-4  col-12 align-items-center justify-content-between  `}
                  >
                    <span
                      className={`${styles.btnDimensions} d-flex flex-row   align-items-center justify-content-center`}
                      onClick={() => setNameDimensions(nameDimensions - 0.1)}
                    >
                      <FontAwesomeIcon icon={faMinus as IconProp} />
                    </span>
                    <span className="text-center text ">
                      {Math.round(nameDimensions * 10) / 10}
                    </span>
                    <span
                      className={`${styles.btnDimensions} d-flex flex-row align-items-center justify-content-center `}
                      onClick={() => setNameDimensions(nameDimensions + 0.1)}
                    >
                      <FontAwesomeIcon icon={faPlus as IconProp} />
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column col-md-3 col-8 mt-5 text-center  ">
                  <span className="sub-title col-12">Company Name Size</span>
                  <div
                    className={`${styles.dimesionsChanger}  d-flex flex-row mt-4  col-12 align-items-center justify-content-between  `}
                  >
                    <span
                      className={`${styles.btnDimensions} d-flex flex-row   align-items-center justify-content-center`}
                      onClick={() =>
                        setCompanyDimensions(companyDimensions - 0.1)
                      }
                    >
                      <FontAwesomeIcon icon={faMinus as IconProp} />
                    </span>
                    <span className="text-center text ">
                      {Math.round(companyDimensions * 10) / 10}
                    </span>
                    <span
                      className={`${styles.btnDimensions} d-flex flex-row align-items-center justify-content-center `}
                      onClick={() =>
                        setCompanyDimensions(companyDimensions + 0.1)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus as IconProp} />
                    </span>
                  </div>
                </div>
                {imageSrc && (
                  <div className="d-flex flex-column col-md-2 col-8 mt-5 text-center ">
                    <span className="sub-title">Logo Size</span>

                    <div className=" col-12 mt-3">
                      <input
                        id="rangeInput"
                        className="col-12 "
                        type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={scale}
                        onChange={(e) => setScale(parseFloat(e.target.value))}
                        style={{
                          background: `linear-gradient(to right, black ${scale}% , #ddd ${scale}%)`,
                        }}
                      />
                    </div>
                  </div>
                )}
                <span className="text  col-8 text-center mt-5">
                  <i>
                    You can move the name and the qr code inside the card by
                    dragging them
                  </i>
                </span>
                <div
                  className={`${styles.cardContainer} d-flex flex-row align-items-center justify-content-center col-12`}
                >
                  <span
                    ref={containerRef}
                    className={`${styles.card}   col-12`}
                  >
                    <span className={`${styles.nfcIcon}`}>
                      <NfcIcon color={qrRGB} />
                    </span>
                    <span
                      ref={squareRef}
                      onMouseDown={startDragging}
                      onTouchStart={startDragging}
                      style={{
                        transform: `translate(${targetX}px, ${targetY}px)`,
                      }}
                      className={`${styles.qrCode}  d-flex flex-column align-items-center justify-content-center `}
                    >
                      <QRCodeCanvas
                        value={"https://dlctech.it"}
                        size={qrDimensions * 1.33}
                        fgColor={qrRGB}
                        bgColor={"rgba(0,0,0,0)"}
                        className=""
                      />
                    </span>
                    <span
                      ref={lineRef}
                      className={`fw-bold ${styles.namesurname}`}
                      onMouseDown={startDraggingLine}
                      onTouchStart={startDraggingLine}
                      style={{
                        color: qrRGB,
                        fontSize: `${nameDimensions}pt`,
                        fontFamily: nameSurnameFont,
                        transform: `translate(${targetLineX}px, ${targetLineY}px)`,
                      }}
                    >
                      {name + " " + surname}
                    </span>
                    <span
                      ref={companyRef}
                      className={`fw-bold ${styles.namesurname}`}
                      onMouseDown={startDraggingCompany}
                      onTouchStart={startDraggingCompany}
                      style={{
                        color: qrRGB,
                        fontSize: `${companyDimensions}pt`,
                        fontFamily: companyFont,
                        transform: `translate(${targetCompanyX}px, ${targetCompanyY}px)`,
                      }}
                    >
                      {companyName}
                    </span>
                    {imageSrc && originalDimensions && (
                      <span
                        ref={logoRef}
                        className={`${styles.namesurname}`}
                        onMouseDown={startDraggingLogo}
                        onTouchStart={startDraggingLogo}
                        style={{
                          maxWidth: `100%`,
                          width: (originalDimensions.width * scale) / 100,
                          height: (originalDimensions.height * scale) / 100,
                          fontFamily: "helvetica",
                          transform: `translate(${targetLogoX}px, ${targetLogoY}px)`,
                        }}
                      >
                        <img src={imageSrc} alt="Preview" />
                      </span>
                    )}

                    <img src={cardColor.image} />
                  </span>
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-center ">
                  <div className="d-flex flex-column align-items-center justify-content-center col-6">
                    <FontSelector
                      title={"Name/Surname Font"}
                      fontSelected={(font) => setNameSurnameFont(font)}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5">
                  <div className="d-flex flex-column align-items-center justify-content-center col-6">
                    <FontSelector
                      title={"Company Name Font"}
                      fontSelected={(font) => setCompanyFont(font)}
                    />
                  </div>
                </div>
                {printType !== "engraved" && (
                  <div className=" col-12 d-flex flex-column align-items-center justify-content-center mt-5">
                    <span className="sub-title">Prints Color: </span>
                    <span className="mt-3">
                      <RgbColorPicker color={qrColor} onChange={setQrColor} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {successMessage && (
            <div className="d-flex flex-row col-12 mt-5  text text-center align-items-center justify-content-center">
              {successMessage}
            </div>
          )}
          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7   ">
            <button className="btn-black sub-title  col-6 rounded">
              UPLOAD DESIGN
            </button>
          </div>
        </form>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default CardDetailCreation;
